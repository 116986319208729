* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body,
input,
button,
textarea {
  font: 600 18px Nunito, sans-serif;
}

.leaflet-container {
  height: 100vh;
  width: 100%;
}

#map {
  width: 100%;
  height: 100vh;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.bg-g {
  color: darkgreen;
}

.bg-r {
  color: red;
}

.bg-y {
  color: #dba901;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100vh;
  width: 80vw;
}

/* react-tabs */
.react-tabs__tab-list {
  border: none !important;
  margin: 0 !important;
}
.react-tabs__tab {
  border: 1px solid black !important;
  border-radius: 20px !important;
  margin-right: 5px;
  padding: 2px 14px !important;
}
.react-tabs__tab--selected {
  background: black !important;
  color: white !important;
}

/* custom legends */
.list-legends {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.list-legends li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.list-legends .line {
  width: 20px;
  height: 4px;
  margin-right: 5px;
  position: relative;
  border-radius: 3px;
}

.list-legends .bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -3px;
  left: 5px;
}
