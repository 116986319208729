
table.radar {
    border: 0px;
    font-size: 10px;
    text-align: center;
    border-spacing: 0;
    border-collapse: collapse;

}

td.radarBordaTop {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
}

td.radarBordaInf {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
}
