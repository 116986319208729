::-webkit-input-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

::placeholder {
  color: #fff;
  opacity: 1;
}

.redemet-sidebar-trigger {
  display: flex;
  flex-direction: column;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 50px;
  margin: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.6);
  background-color: #fff;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.5s;
}
.redemet-sidebar-trigger:hover {
  background-color: rgb(240, 240, 240);
}
.redemet-sidebar-trigger:hover .bar {
  background-color: #000;
}
.redemet-sidebar-trigger .bar {
  width: 100%;
  height: 3px;
  background-color: #666;
  display: block;
  font-size: 0.1em;
}
.redemet-sidebar-trigger.is-active {
  transform: scale(0);
}
.redemet-sidebar-trigger.is-active .bar {
  background-color: rgb(0, 104, 223);
}

.redemet-mainsidebar {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  color: #fff;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  transform: translate(-100%, 0);
  transition: transform 0.5s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.redemet-mainsidebar.is-active {
  transform: translate(0, 0);
}

.redemet-mainsidebar .redemet-mainsidebar-logo {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.redemet-mainsidebar .redemet-mainsidebar-logo .logo {
  flex-grow: 1;
}
.redemet-mainsidebar .redemet-mainsidebar-logo .toggle-sidebar {
  border: none;
  border-left: 1px solid rgb(226, 226, 226);
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
}
.redemet-mainsidebar .redemet-mainsidebar-logo .toggle-sidebar:hover {
  background-color: rgb(240, 240, 240);
}
.redemet-mainsidebar .redemet-mainsidebar-logo .toggle-sidebar svg {
  transform: rotate(270deg);
}
.redemet-mainsidebar .redemet-mainsidebar-logo .toggle-sidebar svg line {
  stroke: #888;
}

.redemet-mainsidebar .redemet-mainsidebar-scroller {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
}
.redemet-mainsidebar .redemet-mainsidebar-scroller .redemet-mainsidebar-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  height: 100%;
}
.redemet-mainsidebar
  .redemet-mainsidebar-scroller
  .redemet-mainsidebar-inner
  > * {
  width: 100%;
}
.redemet-mainsidebar-section {
  padding: 10px 20px;
}
.redemet-mainsidebar-section p {
  margin-bottom: 10px;
}
.redemet-mainsidebar-divider {
  margin: 0;
  padding: 0;
  height: 0.5pt;
  background-color: #fff;
}

.redemet-mainsidebar-searchfilter {
  border: 1px solid #fff;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
}
.redemet-mainsidebar-searchfilter svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.redemet-mainsidebar-searchfilter svg path {
  fill: #fff;
}
.redemet-mainsidebar-searchfilter input[type="search"] {
  flex: 1;
  border: none;
  width: 100%;
  background-color: transparent;
  margin-right: 10px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  padding: 3px;
}

.redemet-mainsidebar-collapsible > .collapsible-content-wrapper {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  /* height:auto;
  max-height: 0; */
  height: 0;
}
.redemet-mainsidebar-collapsible > .collapsible-content {
  transform: scaleY(0);
  opacity: 0;
  height: auto;
  margin-top: 20px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform-origin: top;
}
.redemet-mainsidebar-collapsible .collapsible-titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.redemet-mainsidebar-collapsible .collapsible-titlebar .icon-start {
  margin-right: 10px;
}
.redemet-mainsidebar-collapsible .collapsible-titlebar .title {
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
}
.redemet-mainsidebar-collapsible .collapsible-titlebar .icon-end {
  margin-left: 10px;
}

.redemet-mainsidebar-collapsible.is-open > .collapsible-content-wrapper {
  /* max-height: 200px; */
  height: auto;
}
.redemet-mainsidebar-collapsible.is-open > .collapsible-content {
  transform: scaleY(1);
  opacity: 1;
}
.redemet-mainsidebar-collapsible.child .collapsible-titlebar {
  padding: 5px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  user-select: none;
}
.redemet-mainsidebar-collapsible.child .collapsible-titlebar .icon-end {
  opacity: 0.5;
  font-size: 12px;
}
.redemet-mainsidebar-collapsible.child .collapsible-titlebar:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.redemet-mainsidebar-collapsible.child .collapsible-titlebar .title {
  text-transform: none;
}
.redemet-mainsidebar-collapsible.child
  .collapsible-titlebar
  .title
  .item-wrapper {
  padding: 0 10px;
}
.redemet-mainsidebar-collapsible.child.is-open
  .collapsible-titlebar
  .title
  .item-wrapper {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
}

.redemet-mainsidebar-menu {
  margin: 0;
  padding: 0;
}
.redemet-mainsidebar-menu .redemet-mainsidebar-menu-item {
  list-style: none;
  padding: 5px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  user-select: none;
}
.redemet-mainsidebar-menu .redemet-mainsidebar-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.redemet-mainsidebar-menu .redemet-mainsidebar-menu-item span {
  display: inline-block;
  padding: 0 10px;
}
.redemet-mainsidebar-menu .redemet-mainsidebar-menu-item.is-active span {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
}
.redemet-mainsidebar-menu .redemet-mainsidebar-menu-item a.item-wrapper {
  display: block;
  color: #fff;
  text-decoration: none;
}

.redemet-mainsidebar-spacer {
  flex-grow: 1;
  min-height: 100px;
}
