#page-map {
  width: 100vw;
  height: 100vh;
}

.options-components {
  width: 90%;

  #border: 1px solid #d3e2e5;
  border-radius: 30px;

  padding: 20px 40px;

  position: absolute;
  bottom: 30px;
  margin: 15px;
  left: 10px;
  right: 10px;
  z-index: 1;
}

#page-map .leaflet-container {
  z-index: 0;
}
