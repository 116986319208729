.horizontal-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
}
.horizontal-menu-trigger {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: .1px solid #efefef;
}
.horizontal-menu-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 10000;
  top: 48px;
  left: 0;
  bottom: 0;
  align-items: stretch;
  transition: all 0.5s ease-in-out;
  overflow-y: initial;
  flex: 1;
  transform: translate(-100%, 0);
  width: 245px;
}
.horizontal-menu-wrapper.show {
  transform: translate(0, 0);
}
.horizontal-menu-wrapper.horizontal-menu-active {
  left: 250px;
}
.horizontal-menu-item {
  list-style: none;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.horizontal-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.horizontal-menu-item-spacer {
  flex: 1;
  pointer-events: none;
}
.horizontal-menu-item-static {
  pointer-events: none;
}
.horizontal-menu-margin {
  margin-top: 0;
}
.horizontal-menu-logo {
  display: flex;
  align-items: stretch;
  background-color: #fff;
  align-content: center;
}
.horizontal-menu-logo .logo {
  padding: 0 20px;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .horizontal-menu-margin {
    margin-top: 80px;
  }
  .horizontal-menu-trigger {
    display: none;
  }
  .horizontal-menu {
    flex-direction: row;
    right: 0;
    bottom: auto;
    height: 80px;
  }
  .horizontal-menu-wrapper {
    flex-direction: row;
    position: static;
    height: 80px;
    overflow-y: initial;
    transform: none;
    width: auto;
  }
  .horizontal-menu-item {
    padding: 0 20px;
  }
}