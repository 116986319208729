.leaflet-bottom.leaflet-right {
  z-index: 999;
}
.layer-control.layer-control-container {
  background: #fff;
  width: 300px;
  max-height: 350px;
  overflow: hidden;
  border-radius: 13px;
  padding-bottom: 5px;
}
.layer-control.layer-control-container-hidden {
  background: #fff;
  width: 170px;
  overflow: hidden;
  border-radius: 13px;
  padding-bottom: 0px;
}
.layer-control > .header {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  color: #08232f;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
  margin-right: 5px;
}
.layer-control > .header .title,
.layer-control > .header .title-suffix {
  padding: 5px 15px;
}
.layer-control > .header .title-hidden,
.layer-control > .header .title-suffix-hidden {
  padding-left: 3px;
  padding-right: 3px;
}
.layer-control > .header .title + .title-suffix {
  border-left: 1px solid #ecf1f4;
}

.layer-control > .header .header-spacer {
  flex-grow: 1;
}

.layer-control .layer-control-content {
  max-height: 0;
  overflow: hidden;
  transform: translateY(0);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    max-height 0.5s ease-in-out;
}

.layer-control.is-active .layer-control-content {
  width: 100%;
  max-height: 350px;
  transform: translate(1);
  opacity: 1;
}
.layer-control.is-active .layer-control-content .scroller {
  max-height: 350px;
}
/* .layer-control > .scroller .content {
  height: auto;
  opacity: 1;
  transform: translateY(1);
  transform-origin: top;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
} */
/* .layer-control > .scroller.hide-content .content {
  display: none;
}
.layer-control > .scroller .atualizacoes {
  display: none;
}
.layer-control > .scroller.hide-content .atualizacoes {
  display: block;
} */

.layer-control .scroller .updated-at {
  padding: 2px;
  text-align: center;
  font-size: 12px;
  color: rgb(44, 44, 44);
  background-color: rgb(209, 209, 209);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(209, 209, 209);
}
.layer-control .updated-at .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.layer-control .MuiTabs-root {
  font-size: 0.7rem;
}
.layer-control .PrivateTabIndicator-root-1 {
  background-color: #448eb6;
}
@media (min-width: 600px) {
  .layer-control .MuiTabs-root .MuiTab-root {
    min-width: 50%;
  }
}

.chip-list {
  padding: 5px 5px;
  text-align: center;
}
.chip-list .MuiChip-root,
.chip-list .MuiChip-root:focus {
  font-size: 0.7rem;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
}
.chip-list .MuiChip-root.MuiChip-outlined {
  color: #000;
  border-color: #000;
  background-color: #fff;
}

.chip-list .MuiChip-root + .MuiChip-root {
  margin: 2px 2px;
}

.timeline-control {
  display: flex;
  flex-direction: row;
}
.timeline-control .player {
  padding: 3px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ecf1f4;
}
.timeline-control .player .MuiIconButton-root {
  color: #000;
}
.timeline-control .player .MuiSlider-root {
  flex-grow: 1;
  margin: 0 5px;
  color: #000;
}
.timeline-control .velocity-control {
  background-color: #ecf1f4;
  margin-left: 1px;
}
.timeline-control .velocity-control .chip-list {
  padding: 3px;
}
.timeline-control
  .velocity-control
  .chip-list
  .MuiChip-root
  .MuiChip-labelSmall {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
}

.layer-control-item {
  padding: 2px 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ecf1f4;
}
.layer-control-item .MuiSlider-root {
  flex-grow: 1;
  margin: 0 10px;
  color: #000;
}
.layer-control-item label {
  font-size: 11px;
}

.gradient-legend {
  margin: 0 5px;
  margin-bottom: 0px;
}

.gradient-legend .gradient-legend-title {
  font-weight: bold;
  text-align: center;
}
.gradient-legend .gradient-legend-values {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #666;
  padding: 5px 0;
}
.gradient-legend .legend-values {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 1px solid #000;
  margin-bottom: 5px;
  padding-bottom: 5px;
  position: relative;
}
.gradient-legend .legend-values .value {
  display: inline-block;
  flex: 1;
  text-align: left;
  position: relative;
}
.gradient-legend .legend-values .value:before {
  content: "";
  height: 5px;
  width: 1px;
  background-color: #000;
  position: absolute;
  bottom: -5px;
  left: 5px;
}
.gradient-legend .legend-values .value:first-child {
  text-align: left;
}
.gradient-legend .legend-values .value:first-child:before {
  right: unset;
  left: 0;
}
.gradient-legend .legend-values .value:last-child {
  text-align: right;
  flex: 0;
  position: absolute;
  right: 0;
}
.gradient-legend .legend-values .value:last-child:before {
  right: 0;
  left: unset;
}
.gradient-legend .gradient-top-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.gradient-legend .gradient-top-labels .top-label {
  display: inline-block;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
}

.gradient-legend .legend-values.top {
  border: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #000;
  margin-top: 5px;
  padding-top: 5px;
  position: relative;
}

.gradient-legend .legend-values.top .value:before {
  top: -5px;
  left: 5px;
}
.gradient-legend .legend-values.top .value:first-child {
  text-align: left;
}
.gradient-legend .legend-values.top .value:first-child:before {
  right: unset;
  left: 0;
}
.gradient-legend .legend-values.top .value:last-child {
  text-align: right;
  flex: 0;
  position: absolute;
  right: 0;
}
.gradient-legend .legend-values.top .value:last-child:before {
  right: 0;
  left: unset;
}

.atualizacoes-list {
  padding: 5px;
}
.atualizacoes-list h4 {
  text-align: center;
  margin-bottom: 0px;
}
.atualizacao-item {
  text-align: center;
}
.atualizacao-item h5 {
  margin-bottom: 0px;
}
.atualizacao-item .atualizar .MuiSvgIcon-root {
  font-size: 18px;
}
.atualizacao-item + .atualizacao-item {
  border-top: 1px solid #ccc;
}
.atualizacao-item + .atualizacao-item:last-child {
  border-top: none;
}

.label {
  margin: 0px 2px 0px 5px;
}
